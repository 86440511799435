// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dyp535A9b1dXmJVIo9gVhg\\=\\= {\n    display: flex;\n    flex-direction: column;\n    background-color: #dedede;\n    min-height: 100vh;\n}\n\n.ozKMbKPrKt68oduKPGRcjg\\=\\= {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 768px;\n    margin: 0 auto;\n}\n\n.Ccwza3F1XPj0HTmxD2cLUg\\=\\= {\n    position: sticky;\n    top: 0;\n    z-index: 555;\n}\n", "",{"version":3,"sources":["webpack://./src/root.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    background-color: #dedede;\n    min-height: 100vh;\n}\n\n.content {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 768px;\n    margin: 0 auto;\n}\n\n.header {\n    position: sticky;\n    top: 0;\n    z-index: 555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dyp535A9b1dXmJVIo9gVhg==",
	"content": "ozKMbKPrKt68oduKPGRcjg==",
	"header": "Ccwza3F1XPj0HTmxD2cLUg=="
};
export default ___CSS_LOADER_EXPORT___;
